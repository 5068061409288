import React, { Component } from 'react';
import grapesjs from 'grapesjs';
import 'grapesjs/dist/css/grapes.min.css';
import mjml from 'grapesjs-mjml/src/index';

class GrapesJS extends Component {
  state = {
    editor: null
  };

  id = 'grapesjs-react-editor';

  componentDidMount() {
    const { record } = this.props;
    const html = record.extra && record.extra.html ? record.extra.html : '';

    if (!this.state.editor) {
      const editor = grapesjs.init({
        fromElement: true,
        container: `#${this.id}`,
        plugins: [mjml],
        storageManager: {
          id: 'gjs-', // Prefix identifier that will be used on parameters
          type: 'local', // Type of the storage
          autosave: true, // Store data automatically
          autoload: html === '' ? true : false, // Autoload stored data on init
          stepsBeforeSave: 1 // If autosave enabled, indicates how many changes are necessary before store method is triggered
        },
        assetManager: {
          upload: process.env.REACT_APP_BACKEND_URL + '/upload',
          uploadName: 'files',
          uploadFile: e => {
            let files = e.dataTransfer ? e.dataTransfer.files : e.target.files;
            var formData = new FormData();
            formData.append('files', files[0]);
            formData.append('provider', 'local');
            fetch(process.env.REACT_APP_BACKEND_URL + '/upload', {
              method: 'POST',
              headers: {
                Authorization: 'Bearer ' + localStorage.getItem('jwt')
              },
              body: formData
            })
              .then(res => res.json())
              .then(response => {
                editor.AssetManager.add(response[0].url);
              })
              .catch(error => console.log(error));
          }
        }
      });

      const rte = editor.RichTextEditor;
      rte.remove('bold');
      rte.remove('italic');
      rte.remove('underline');
      rte.remove('strikethrough');
      rte.remove('link');

      this.setState({ editor: editor });
    }
  }

  render() {
    const { record } = this.props;
    const html = record.extra && record.extra.html ? record.extra.html : '';

    return html === '' ? (
      <div id={this.id}>
        <mjml>
          <mj-body width="960px">
            <mj-section>
              <mj-column>
                <mj-text>Free Template.</mj-text>
              </mj-column>
            </mj-section>
          </mj-body>
        </mjml>
      </div>
    ) : (
      <div id={this.id} dangerouslySetInnerHTML={{ __html: html }} />
    );
  }
}
export default GrapesJS;
