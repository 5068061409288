import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Button } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import LockIcon from '@material-ui/icons/Lock';
import { withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';

const styles = {
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: 'url(https://source.unsplash.com/random/1600x900/daily)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  card: {
    minWidth: 300,
    marginTop: '6em',
    textAlign: 'center',
    padding: '2em'
  },
  avatar: {
    margin: '1em',
    display: 'flex',
    justifyContent: 'center'
  }
};

const JNJLogin = ({ classes }) => {
  function renderRedirect() {
    if (localStorage.getItem('confirmed') == 'false') {
      return <FormHelperText error>Please provide your account to the administrator to open the permission</FormHelperText>
    }
    // window.location.href = `${process.env.REACT_APP_BACKEND_URL}/connect/jnj`;
  }

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        {renderRedirect()}
        <div className={classes.avatar}>
          <Avatar>
            <LockIcon />
          </Avatar>
        </div>
        <Button variant="contained" color="primary" href="/connect/jnj">
          Sign In
        </Button>
      </Card>
    </div>
  );
};

export default withStyles(styles)(JNJLogin);
