import React from 'react';
import { Admin, Resource, fetchUtils, Layout } from 'react-admin';
import AppBar from './layout/AppBar';
import Login from './layout/Login';
import StrapiDataProvider from 'ra-data-strapi';
import authProvider from './authProvider';
import './App.css';
import newsLetters from './pages/Newsletter';
import CustomRoutes from './pages/CustomRoutes';
import addGenerateMjmlFeature from './utils/addGenerateMjmlFeature';

import IconList from './pages/Icons/IconList';
import IconCreate from './pages/Icons/IconCreate';
import IconShow from './pages/Icons/IconShow';
import IconEdit from './pages/Icons/IconEdit';
import VideoList from './pages/Videos/VideoList';
import VideoCreate from './pages/Videos/VideoCreate';
import VideoShow from './pages/Videos/VideoShow';
import VideoEdit from './pages/Videos/VideoEdit';

import {
  PictureInPictureAlt,
  Spa,
  Photo,
  Description,
  Videocam,
  ViewQuilt
} from '@material-ui/icons';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('jwt');
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = StrapiDataProvider(
  process.env.REACT_APP_BACKEND_URL,
  httpClient
);

const mjmlCapableDataProvider = addGenerateMjmlFeature(dataProvider);

const App = () => {
  return (
    <Admin
      customRoutes={CustomRoutes}
      dataProvider={mjmlCapableDataProvider}
      authProvider={authProvider}
      appLayout={props => <Layout {...props} appBar={AppBar} />}
      loginPage={Login}
    >
      {permissions => [
        // position share at fisrt ensure share video SSO can redirect to share page
        localStorage.getItem('destination_video_id') && (
          <Resource name="share" />
        ),
        permissions === 'editor' && (
          <Resource name="newsletters" {...newsLetters} />
        ),
        <Resource
          name="icons"
          list={IconList}
          edit={permissions === 'editor' ? IconEdit : null}
          create={permissions === 'editor' ? IconCreate : null}
          show={IconShow}
          icon={PictureInPictureAlt}
        />,
        <Resource
          name="logos"
          list={IconList}
          edit={permissions === 'editor' ? IconEdit : null}
          create={permissions === 'editor' ? IconCreate : null}
          show={IconShow}
          icon={Spa}
        />,
        <Resource
          name="photographies"
          options={{ label: 'Photographs' }}
          list={IconList}
          edit={permissions === 'editor' ? IconEdit : null}
          create={permissions === 'editor' ? IconCreate : null}
          show={IconShow}
          icon={Photo}
        />,
        <Resource
          name="documents"
          list={IconList}
          edit={permissions === 'editor' ? IconEdit : null}
          create={permissions === 'editor' ? IconCreate : null}
          show={IconShow}
          icon={Description}
        />,
        <Resource
          name="videos"
          list={VideoList}
          edit={permissions === 'editor' ? VideoEdit : null}
          create={permissions === 'editor' ? VideoCreate : null}
          show={VideoShow}
          icon={Videocam}
        />,
        <Resource
          name="templates"
          list={IconList}
          edit={permissions === 'editor' ? IconEdit : null}
          create={permissions === 'editor' ? IconCreate : null}
          show={IconShow}
          icon={ViewQuilt}
        />,
        <Resource name="links" />,
        <Resource name="links/aggregate" />
      ]}
    </Admin>
  );
};

export default App;
