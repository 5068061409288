import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  FileInput,
  LongTextInput,
  required
} from 'react-admin';
import UploadField from '../../components/UploadField';
import ChipArrayInput from '../../components/ChipArrayInput';

const EditTitle = ({ record }) => {
  return <span>{record ? `${record.title}` : ''}</span>;
};

const VideoEdit = props => (
  <Edit {...props} title={<EditTitle />}>
    <SimpleForm redirect="show">
      <FileInput
        validate={[required()]}
        source="pcvideo"
        label="PC video"
        accept="video/mp4"
      >
        <UploadField source="src" title="title" form="pcvideo" />
      </FileInput>
      <FileInput source="mobilevideo" label="Mobile video" accept="video/mp4">
        <UploadField source="src" title="title" form="mobilevideo" />
      </FileInput>
      <FileInput source="previewimg" label="Preview image" accept="image/*">
        <UploadField source="src" title="title" form="previewimg" />
      </FileInput>
      <TextInput
        style={{ width: '100%' }}
        source="title"
        validate={[required()]}
      />
      <ChipArrayInput
        style={{ width: '100%' }}
        source="keywords"
        label="Keywords ( Press return to add )"
      />
      <LongTextInput source="description" />
      <LongTextInput source="usageInstructions" />
    </SimpleForm>
  </Edit>
);

export default VideoEdit;
