import React, { Fragment } from 'react';
import Typography from '@material-ui/core/Typography';

const LabeledTextField = ({ label, value }) => {
  return (
    <Fragment>
      <Typography style={{ color: '#2b2d2f' }} variant="body1" gutterBottom>
        {label}
      </Typography>
      <Typography
        style={{
          color: '#80858e',
          marginBottom: '20px',
          textOverflow: 'ellipsis',
          overflow: 'hidden'
        }}
        variant="body2"
        gutterBottom
      >
        {value}
      </Typography>
    </Fragment>
  );
};

export default LabeledTextField;
