import React from 'react';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import DraftsIcon from '@material-ui/icons/Drafts';
import Chip from '@material-ui/core/Chip';
import Typography from '@material-ui/core/Typography';
import TrackField from './TrackField';
import { getDateTimeOf } from '../../utils/common';
import { Link } from 'react-router-dom';
import { linkToRecord, Button } from 'react-admin';
import { KeyboardArrowDown } from '@material-ui/icons';

const cardStyle = {
  width: '100%',
  minHeight: 50,
  margin: '0.5em',
  display: 'inline-block',
  verticalAlign: 'top'
};

const headerStyle = {
  minWidth: '30%',
  display: 'inline-flex'
};

const inLineContentStyle = {
  display: 'inline-flex'
};

const NewsLetterGrid = ({ ids, data, basePath }) => {
  return (
    <div style={{ margin: '1em' }}>
      {ids.map(id => (
        <Link
          key={id}
          style={{ textDecoration: 'none' }}
          to={linkToRecord(basePath, id, 'show')}
        >
          <Card key={id} style={cardStyle}>
            <CardHeader
              style={headerStyle}
              avatar={
                <Avatar aria-label="Recipe">
                  <DraftsIcon />
                </Avatar>
              }
              title={data[id]['subject']}
              subheader={getDateTimeOf(data[id]['createdAt'])}
            />

            <CardContent style={inLineContentStyle}>
              {data[id]['status'] === 'Sent' ? (
                <Chip label="Sent" color="secondary" />
              ) : (
                  <Chip label="Draft" />
                )}
            </CardContent>
            <CardContent style={inLineContentStyle}>
              <TrackField label={'Opens'} count={data[id]['opensCount']} />
            </CardContent>
            <CardContent style={inLineContentStyle}>
              <TrackField label={'Clicks'} count={data[id]['clicksCount']} />
            </CardContent>
            <CardContent style={{ display: 'inline-flex', float: 'right' }}>
              <Button
                component={Link}
                label="View Detail"
                to={linkToRecord(basePath, id, 'show')}
              >
                <KeyboardArrowDown />
              </Button>
            </CardContent>
            <CardContent>
              <Typography variant="body1" gutterBottom>
                {data[id].status === 'Sent' ? 'Sent ' : 'Edited '}
                {getDateTimeOf(
                  data[id].status === 'Sent'
                    ? data[id].sentAt
                    : data[id].updatedAt
                )}
                {' by '}
                {data[id].author && data[id].author.username}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      ))}
    </div>
  );
};

NewsLetterGrid.defaultProps = {
  data: {},
  ids: []
};

export default NewsLetterGrid;
