import React from 'react';
import { CircularProgress } from '@material-ui/core';

import VideoPlayer from '../../components/VideoPlayer';
import { isMobileDevice, getUrlParams } from '../../utils/common';
import request from '../../services/request';
import api from '../../services/api';
import './index.css';

const WatchVideo = () => {
  const [videoTitle, setVideoTitle] = React.useState(null);

  const [videoUrl, setVideoUrl] = React.useState(null);
  const [previewUrl, setPreviewUrl] = React.useState(null);

  React.useEffect(() => {
    const id =
      getUrlParams('id') || localStorage.getItem('destination_video_id');

    // if url does not have id, set id to url from localstorage
    if (!getUrlParams('id') && localStorage.getItem('destination_video_id')) {
      window.history.pushState('', '', `/#/share?id=${id}`);
    }
    // remove destination_video_id in localStorage
    localStorage.removeItem('destination_video_id');
    if (!id) {
      return;
    }
    request.get(`${api.videos}/${id}`).then(data => {
      setVideoTitle(data.title);
      document.title = data.title;
      if (data.previewimg) {
        setPreviewUrl(data.previewimg.url);
      }
      if (isMobileDevice() && data.mobilevideo) {
        setVideoUrl(data.mobilevideo.url);
      } else {
        setVideoUrl(data.pcvideo.url);
      }
    });
  }, []);

  const videoJsOptions = {
    autoplay: true,
    controls: true,
    poster: `${process.env.REACT_APP_BACKEND_URL}${previewUrl}`,
    preload: 'auto',
    sources: [
      {
        src: `${process.env.REACT_APP_BACKEND_URL}${videoUrl}`,
        type: 'video/mp4'
      }
    ]
  };

  return (
    <div className="videoDiv">
      {videoUrl ? (
        <React.Fragment>
          <div className="videoTitle">{videoTitle}</div>
          <VideoPlayer
            {...videoJsOptions}
            style={{ display: 'block' }}
            isShare={true}
          />
        </React.Fragment>
      ) : (
        <CircularProgress />
      )}
    </div>
  );
};

export default WatchVideo;
