import React, { Component } from 'react';
import { withDataProvider, CREATE } from 'react-admin';

class MjmlField extends Component {
  state = {
    url: ''
  };

  componentDidMount() {
    const { record, dataProvider } = this.props;
    dataProvider(CREATE, 'newsletters/preview', { data: { mjml: record.mjml } })
      .then(({ data: res }) => {
        const blob = new Blob([res.html], { type: 'text/html' });
        const preUrl = URL.createObjectURL(blob);
        this.setState({ url: preUrl });
      })
      .catch(e => console.log(e));
  }

  render() {
    return (
      <div
        style={{
          position: 'relative',
          width: '100%',
          height: 0,
          paddingBottom: '56.25%'
        }}
      >
        <iframe
          frameBorder="0"
          allowFullScreen
          title="iframe"
          style={{
            position: 'absolute',
            top: '0',
            left: '0',
            width: '100%',
            height: '100%'
          }}
          src={this.state.url}
        />
      </div>
    );
  }
}

export default withDataProvider(MjmlField);
