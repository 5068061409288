import React from 'react';
import { Show, SimpleShowLayout, CardActions, EditButton } from 'react-admin';
import get from 'lodash.get';
import VideoPlayer from '../../components/VideoPlayer';
import DeleteButtonWithConfirmation from '../../components/DeleteButtonWithConfirmation';
import LabeledTextField from '../../components/LabeledTextField';
import DownloadButton from '../../components/DownloadButton';
import { getDateTimeOf } from '../../utils/common';
import CopyLinkButton from './CopyLinkButton';

const MyImageField = ({ source, record = {} }) => {
  const id = get(record, source);
  if (!id) {
    return (
      <div
        style={{
          background: '#eeeef0',
          textAlign: 'center'
        }}
      >
        <p
          style={{
            color: '#999',
            verticalAlign: 'middle',
            display: 'inline-block'
          }}
        >
          Empty image
        </p>
      </div>
    );
  }
  return (
    <img
      style={{ maxHeight: '320px', maxWidth: '500px' }}
      src={`${
        process.env.REACT_APP_BACKEND_URL
      }/file/download/${id}?token=${localStorage.getItem('jwt')}`}
      alt="title"
    />
  );
};
MyImageField.defaultProps = { addLabel: true };

const MyVideoField = ({ source, record = {} }) => {
  const id = get(record, source);
  if (!id) {
    return (
      <div
        style={{
          background: '#eeeef0',
          textAlign: 'center'
        }}
      >
        <p
          style={{
            color: '#999',
            verticalAlign: 'middle',
            display: 'inline-block'
          }}
        >
          Empty video
        </p>
      </div>
    );
  }
  const videoJsOptions = {
    autoplay: false,
    controls: true,
    'data-setup': '{"fluid": true}',
    sources: [
      {
        src: `${process.env.REACT_APP_BACKEND_URL}/file/download/${id}`,
        type: 'video/mp4'
      }
    ]
  };
  return (
    <div>
      <VideoPlayer {...videoJsOptions} isShare={false} />
    </div>
  );
};
MyVideoField.defaultProps = { addLabel: true };

const ShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    <EditButton basePath={basePath} record={data} />
    <DeleteButtonWithConfirmation
      basePath={basePath}
      record={data}
      resource={resource}
    />
  </CardActions>
);

const ShowTitle = ({ record }) => {
  return <span>{record ? `${record.title}` : ''}</span>;
};

const VideoAside = ({ record }) => (
  <div style={{ flex: 'none', width: '20%', margin: '1em' }}>
    {record && (
      <div>
        <LabeledTextField label="Image" value={record.title} />
        <DownloadButton
          label="Download Asset"
          file={record.file ? record.file : record.pcvideo}
        />
        <CopyLinkButton record={record} label="Share" />
        <LabeledTextField label="Description" value={record.description} />
        <LabeledTextField
          label="Usage Instructions"
          value={record.usageInstructions}
        />
        <LabeledTextField label="Format" value=".mp4" />
        <LabeledTextField
          label="Last Updated"
          value={getDateTimeOf(record.updatedAt)}
        />
        <LabeledTextField
          label="File Size"
          value={`${(record.pcvideo.size / 1024).toFixed(2)} MB`}
        />
        <LabeledTextField
          label="Keywords"
          value={record.keywords.join(' / ')}
        />
        <LabeledTextField
          label="Date Added"
          value={getDateTimeOf(record.createdAt)}
        />
      </div>
    )}
  </div>
);

const VideoShow = ({ permissions, ...props }) => {
  return (
    <Show
      {...props}
      aside={<VideoAside />}
      title={<ShowTitle />}
      actions={permissions === 'editor' && <ShowActions />}
    >
      <SimpleShowLayout>
        <MyVideoField label="" source="pcvideo.id" title="previewimg.name" />
        {/* <MyVideoField
          label="mobile video"
          source="mobilevideo.id"
          title="previewimg.name"
        />
        <MyImageField
          label="preview image"
          source="previewimg.id"
          title="previewimg.name"
        /> */}
      </SimpleShowLayout>
    </Show>
  );
};

export default VideoShow;
