/**
 * Use URL interface https://developer.mozilla.org/en-US/docs/Web/API/URL
 * new URL(window.location.href)
 * console.log('url.hash', url.hash);
 * console.log('url.host', url.host);
 * console.log('url.hostname', url.hostname);
 * console.log('url.href', url.href);
 * console.log('url.origin', url.origin);
 * console.log('url.password', url.password);
 * console.log('url.pathname', url.pathname);
 * console.log('url.port', url.port);
 * console.log('url.portocol', url.protocol);
 * console.log('url.search', url.search);
 * console.log('url.searchparam', url.searchParams);
 * console.log('url.username', url.username);
 * console.log('url.tostring', url.toString());
 * console.log('url.tojson', url.toJSON());
 * */
const getUrlHost = () => new URL(window.location.href).host;
const getUrlPath = () => new URL(window.location.href).pathname;
/**
 * get url parameters
 * @param {String} paramKey not required. Return corressponding value of the key if there is.
 * Or return all params value with corresponding key as object back.
 */
const getUrlParams = paramKey => {
  const url = window.location.href.replace('#/', '');
  if (paramKey) {
    return new URL(url).searchParams.get(paramKey);
  }
  const params = {};
  const sp = new URL(url).searchParams;
  // eslint-disable-next-line no-restricted-syntax
  for (const key of sp.keys()) {
    params[key] = sp.get(key);
  }
  return params;
};

const setUrlParams = (url, params) => {
  let newUrl = url;
  if (!newUrl) {
    newUrl = getUrlPath();
  }
  if (!params || Object.keys(params).length === 0) {
    return newUrl;
  }
  const param = new URLSearchParams();
  Object.keys(params).forEach(element => {
    param.set(element, params[element]);
  });
  return `${newUrl}?${param.toString()}`;
};

const getDateOf = timestamp => {
  return new Date(timestamp).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

const getDateTimeOf = timestamp => {
  return new Date(timestamp).toLocaleString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
    // second: '2-digit'
  });
};

const isMobileDevice = () => {
  return (
    typeof window.orientation !== 'undefined' ||
    navigator.userAgent.indexOf('IEMobile') !== -1
  );
};

export {
  getUrlHost,
  getUrlPath,
  getUrlParams,
  setUrlParams,
  getDateOf,
  getDateTimeOf,
  isMobileDevice
};
