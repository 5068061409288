import React from 'react';
import { Show, SimpleShowLayout, CardActions, EditButton } from 'react-admin';
import DeleteButtonWithConfirmation from '../../components/DeleteButtonWithConfirmation';
import LabeledTextField from '../../components/LabeledTextField';
import DownloadButton from '../../components/DownloadButton';
import { getDateTimeOf } from '../../utils/common';
import FilePreviewField from '../../components/FilePreviewField';

const IconActions = ({ basePath, data, resource }) => (
  <CardActions>
    <EditButton basePath={basePath} record={data} />
    <DeleteButtonWithConfirmation
      basePath={basePath}
      record={data}
      resource={resource}
    />
  </CardActions>
);

const IconTitle = ({ record }) => {
  return <span>{record ? `${record.title}` : ''}</span>;
};

const IconAside = ({ record }) => (
  <div style={{ flex: 'none', width: '20%', margin: '1em' }}>
    {record && (
      <div>
        <LabeledTextField label="Image" value={record.title} />
        <DownloadButton label="Download Asset" file={record.file} />
        <LabeledTextField label="Description" value={record.description} />
        <LabeledTextField
          label="Usage Instructions"
          value={record.usageInstructions}
        />
        <LabeledTextField label="Format" value={record.file.ext} />
        <LabeledTextField
          label="Last Updated"
          value={getDateTimeOf(record.updatedAt)}
        />
        <LabeledTextField
          label="File Size"
          value={
            record.file.size > 1024
              ? `${(record.file.size / 1024).toFixed(2)} MB`
              : `${record.file.size} KB`
          }
        />
        <LabeledTextField
          label="Keywords"
          value={record.keywords.join(' / ')}
        />
        <LabeledTextField
          label="Date Added"
          value={getDateTimeOf(record.createdAt)}
        />
      </div>
    )}
  </div>
);

const IconShow = ({ permissions, ...props }) => {
  return (
    <Show
      {...props}
      aside={<IconAside />}
      title={<IconTitle />}
      actions={permissions === 'editor' && <IconActions />}
    >
      <SimpleShowLayout>
        <FilePreviewField resource={props.resourceÏ} source="file" src="url" />
      </SimpleShowLayout>
    </Show>
  );
};

export default IconShow;
