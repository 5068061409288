/* eslint-disable no-unused-vars */
import axios from 'axios';

const get = (url, params) => {
  const network = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: { Authorization: `bearer ${localStorage.getItem('jwt')}` }
  });
  return network
    .get(url, params)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err) {
        const { response } = err;
        switch (response.status) {
          case 403:
          case 401:
            window.location.replace('/#/login');
            break;
          default:
        }
      }
      throw err.response;
    });
};

const post = (url, data, config) => {
  const network = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: { Authorization: `bearer ${localStorage.getItem('jwt')}` }
  });
  return network
    .post(url, data, config)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw err.response;
      }
      throw err;
    });
};
const networkForLogin = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL
});

const login = (url, data) => {
  return networkForLogin
    .post(url, data)
    .then(response => {
      return response.data;
    })
    .catch(err => {
      if (err.response) {
        throw err.response;
      }
      throw err;
    });
};

export default {
  get,
  post,
  login
};
