import React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  ImageField,
  LongTextInput,
  FormDataConsumer,
  required,
  FileInput,
  FileField
} from 'react-admin';
import ChipArrayInput from '../../components/ChipArrayInput';

const IconEdit = props => (
  <Edit {...props}>
    <SimpleForm redirect="list">
    <FormDataConsumer>
        {({ formData, ...rest }) =>
          rest.resource === 'documents' || rest.resource === 'templates' ? (
            <FileInput
              source="file"
              label="File"
              accept={'.pdf, .ppt, .pptx, .doc, .docx, .psd, .indd'}
            >
              <FileField source="url" title="title" />
            </FileInput>
          ) : (
            <ImageInput
              label="File"
              source="file"
              accept="image/*, application/pdf, .eps, .tif, .ai"
              validate={[required()]}
              {...rest}
            >
              <ImageField source="url" title="title" />
            </ImageInput>
          )
        }
      </FormDataConsumer>
      <TextInput source="title" validate={[required()]} />
      <ChipArrayInput
        style={{ width: '100%' }}
        source="keywords"
        label="Keywords ( Press return to add )"
      />
      <LongTextInput source="description" />
      <LongTextInput source="usageInstructions" />
    </SimpleForm>
  </Edit>
);

export default IconEdit;
