import React from 'react';
import {
  Create,
  SimpleForm,
  ImageInput,
  ArrayInput,
  ImageField,
  required,
  LongTextInput,
  SelectInput,
  FormDataConsumer,
  REDUX_FORM_NAME
} from 'react-admin';
import { change } from 'redux-form';
import { connect } from 'react-redux';
import DragFormIterator from './DragFormIterator';
import GrapesJS from '../../components/GrapesJS';
import ChipArrayInput from '../../components/ChipArrayInput';

const url = (message = 'url should start with http:// or https://') => value =>
  value && value.startsWith('http') ? undefined : message;

const NewsLetterCreate = ({ dispatch, ...props }) => (
  <Create {...props}>
    <SimpleForm redirect="show" submitOnEnter={false}>
      <LongTextInput
        style={{ width: '100%' }}
        source="subject"
        validate={[required()]}
      />
      <ChipArrayInput
        style={{ width: '100%' }}
        source="to"
        label="To ( Press return to add )"
        validate={[required()]}
      />
      <SelectInput
        source="extra.templateType"
        label="Template type"
        choices={[
          { id: 'standard', name: 'Standard' },
          { id: 'free', name: 'Free' }
        ]}
        defaultValue="standard"
        onChange={props => {
          if (props[0] === 'f') {
            dispatch(change(REDUX_FORM_NAME, 'extra.newsData', null));
          }
        }}
      />
      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.extra && formData.extra.templateType === 'free' ? (
            <div>
              <div style={{ marginBottom: 5 }}>
                <b>Noted: Please only insert one image in one column.</b>
              </div>
              <GrapesJS source="mjml" {...rest} />
            </div>
          ) : (
            <div>
              <img
                style={{ width: '60%' }}
                src="https://commscentral.pr.jnj.com.cn/JnjNews1V2.jpg"
                alt="header"
              />
              <ArrayInput
                style={{ width: '60%' }}
                source="extra.newsData"
                label="News"
                validate={[required()]}
                {...rest}
              >
                <DragFormIterator>
                  <ImageInput
                    source="picture"
                    accept="image/*"
                    validate={[required()]}
                  >
                    <ImageField source="url" title="title" />
                  </ImageInput>
                  <LongTextInput source="media" validate={[required()]} />
                  <LongTextInput source="title" validate={[required()]} />
                  <LongTextInput source="subtitle" validate={[required()]} />
                  <LongTextInput source="url" validate={[url(), required()]} />
                </DragFormIterator>
              </ArrayInput>
              <img
                style={{ width: '60%' }}
                src="https://commscentral.pr.jnj.com.cn/JnjNews2V2.jpg"
                alt="footer"
              />
            </div>
          )
        }
      </FormDataConsumer>
    </SimpleForm>
  </Create>
);

export default connect()(NewsLetterCreate);
