import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import ChipInput from 'material-ui-chip-input';

export class ChipArrayInput extends React.Component {
  render() {
    const { label, source, validate } = this.props;

    return (
      <Field
        name={source}
        component={renderChipInput}
        label={label}
        validate={validate}
        props={this.props}
      />
    );
  }
}

const renderChipInput = ({
  label,
  input,
  validate,
  meta: { touched, error },
  ...rest
}) => (
  <ChipInput
    {...input}
    label={label || 'none'}
    fullWidth={true}
    validate={validate}
    value={input.value || []}
    onAdd={addedChip => {
      let values = input.value || [];
      values = values.slice();
      values.push(addedChip);
      input.onChange(values);
    }}
    onDelete={deletedChip => {
      let values = input.value || [];
      values = values.filter(v => v !== deletedChip);
      input.onChange(values);
    }}
    onBlur={input.onBlur()}
    {...rest}
  />
);

ChipArrayInput.propTypes = {
  className: PropTypes.string,
  input: PropTypes.object,
  isRequired: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  meta: PropTypes.object,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  options: PropTypes.object,
  resource: PropTypes.string,
  source: PropTypes.string,
  type: PropTypes.string
};

ChipArrayInput.defaultProps = {
  onBlur: () => {},
  onChange: () => {},
  onFocus: () => {},
  options: {},
  type: 'text'
};

export default ChipArrayInput;
