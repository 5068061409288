import React from 'react';
import { List, Filter, SearchInput } from 'react-admin';
import NewsLetterGrid from './NewsLetterGrid';

const NewsLetterFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
);

const NewsLetterList = props => {
  return (
    <List
      {...props}
      filters={<NewsLetterFilter />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={false}
    >
      <NewsLetterGrid />
    </List>
  );
};

export default NewsLetterList;
