import NewsLetterList from './NewsLetterList';
import NewsLetterCreate from './NewsLetterCreate';
import NewsLetterShow from './NewsLetterShow';
import NewsLetterEdit from './NewsLetterEdit';
import Mail from '@material-ui/icons/Mail';

export default {
  icon: Mail,
  list: NewsLetterList,
  create: NewsLetterCreate,
  show: NewsLetterShow,
  edit: NewsLetterEdit
};
