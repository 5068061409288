import React from 'react';
import {
  List,
  Responsive,
  Filter,
  TextInput,
  SearchInput,
  SelectArrayInput
} from 'react-admin';
import MediaGrid from '../../components/MediaGrid';
import options from '../../json/options.json';

const IconFilter = props => (
  <Filter {...props}>
    <SearchInput label="Search Title" source="q" alwaysOn />
    <TextInput label="Keywords" source="keywords_contains" alwaysOn />
    <SelectArrayInput
      label="File Format"
      source="file.ext"
      choices={
        props.resource === 'documents' || props.resource === 'templates'
          ? options.documentFileFormat
          : options.fileFormat
      }
      alwaysOn
    />
    <SelectArrayInput
      label="File Size"
      source="file.size_lt"
      choices={options.fileSize}
      alwaysOn
    />
  </Filter>
);

const IconList = props => {
  return (
    <List
      {...props}
      filters={<IconFilter />}
      perPage={60}
      sort={{ field: 'createdAt', order: 'DESC' }}
      exporter={false}
    >
      <Responsive medium={<MediaGrid />} />
    </List>
  );
};

export default IconList;
