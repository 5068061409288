/* eslint-disable prefer-promise-reject-errors */
// in src/authProvider.js
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from 'react-admin';
import request from './services/request';
import api from './services/api';
import { getUrlParams } from './utils/common';

export default (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const para = {
      identifier: username,
      password
    };
    return request.login(api.login, para).then(res => {
      localStorage.setItem('jwt', res.jwt);
      localStorage.setItem('user', res.user.role.type);
      localStorage.setItem('confirmed', res.user.confirmed);
      return Promise.resolve();
    });
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    localStorage.removeItem('jwt');
    localStorage.removeItem('user');
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status === 401 || status === 403) {
      localStorage.removeItem('jwt');
      localStorage.removeItem('user');
      return Promise.reject({ redirectTo: '/#/login' });
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    if (localStorage.getItem('confirmed') === 'false'){
      localStorage.removeItem('jwt');
      localStorage.removeItem('user');
      localStorage.setItem('confirmed', 'false');
    }
    if (localStorage.getItem('jwt')) {
      return Promise.resolve();
    }
    return Promise.reject();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    if (window.location.href.includes('/share')) {
      localStorage.setItem('destination_video_id', getUrlParams('id'));
    }
    const role = localStorage.getItem('user');
    return role ? Promise.resolve(role) : Promise.reject();
  }
  return Promise.reject(new Error('Unknown method'));
};
