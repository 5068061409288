import React, { Fragment } from 'react';
import Button from '@material-ui/core/Button';
import services from '../services/services';
import DownloadIcon from '@material-ui/icons/FileDownload';

const DownloadButton = ({ label, file }) => {
  return (
    <Fragment>
      <Button
        style={{
          marginBottom: '20px',
          paddingLeft: '0px',
          color: '#3f51b5'
        }}
        onClick={() => services.download(file)}
      >
        <DownloadIcon style={{ paddingRight: '0.5em' }} />
        {label}
      </Button>
    </Fragment>
  );
};

export default DownloadButton;
