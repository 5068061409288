import React from 'react';
import { List, Responsive, Filter, TextInput, SearchInput } from 'react-admin';
import MediaGrid from '../../components/MediaGrid';
import CustomPagination from '../../components/CustomPagination/CustomPagination';

const PostFilter = props => (
  <Filter {...props}>
    <SearchInput label="Search Title" source="q" alwaysOn />
    <TextInput label="Keywords" source="keywords_contains" alwaysOn />
  </Filter>
);

const VideoList = props => {
  return (
    <List
      {...props}
      filters={<PostFilter />}
      perPage={50}
      sort={{ field: 'createdAt', order: 'DESC' }}
      pagination={<CustomPagination />}
      exporter={false}
    >
      <Responsive medium={<MediaGrid />} />
    </List>
  );
};

export default VideoList;
