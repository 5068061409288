import React from 'react';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';

const TrackField = props => {
  const { label, count } = props;

  return (
    <div>
      <Typography>{count}</Typography>
      <Typography variant="caption" display="block">
        {label}
      </Typography>
    </div>
  );
};

TrackField.propTypes = {
  label: PropTypes.string,
  count: PropTypes.number
};

export default TrackField;
