import React from 'react';
import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Email } from '@material-ui/icons';
import { CREATE, withDataProvider, Button } from 'react-admin';
import ChipInput from 'material-ui-chip-input';

function SendTestButton(props) {
  const [open, setOpen] = React.useState(false);
  const [emails, setEmails] = React.useState([]);
  const { id, dataProvider } = props;

  function handleClickOpen() {
    setEmails([]);
    setOpen(true);
  }

  function handleClose() {
    setEmails([]);
    setOpen(false);
  }

  function handleConfirm() {
    if (emails.length === 0) {
      return;
    }
    const resource = 'newsletters/' + id + '/test';
    dataProvider(
      CREATE,
      resource,
      { data: { to: emails } },
      {
        onSuccess: {
          notification: { body: 'Send test email succeed', level: 'info' },
          callback: handleClose
        },
        onError: {
          notification: {
            body: 'Error: Send test email failed',
            level: 'warning',
            callback: setOpen(false)
          }
        }
      }
    );
  }

  return (
    <span>
      <Button label="Send Test" color="primary" onClick={handleClickOpen}>
        <Email style={{ marginRight: 5 }} />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Test Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            To send the test email, please input your email address to receive
            the test email.
          </DialogContentText>
          <ChipInput
            label="Email Address"
            autoFocus
            fullWidth={true}
            value={emails}
            onAdd={addedChip => {
              let values = emails || [];
              values = values.slice();
              values.push(addedChip);
              setEmails(values);
            }}
            onDelete={deletedChip => {
              let values = emails || [];
              values = values.filter(v => v !== deletedChip);
              setEmails(values);
            }}
            helperText={'Press return to add'}
          />
          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            value={emails}
            onChange={e => setEmails([e.target.value])}
            fullWidth
          /> */}
        </DialogContent>
        <DialogActions>
          <MuiButton onClick={handleClose} color="primary">
            Cancel
          </MuiButton>
          <MuiButton onClick={handleConfirm} color="primary">
            Confirm
          </MuiButton>
        </DialogActions>
      </Dialog>
    </span>
  );
}

export default withDataProvider(SendTestButton);
