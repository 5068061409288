const download = file => {
  // fetch tmp token
  let token = `Bearer ${localStorage.getItem('jwt')}`;
  fetch(`${process.env.REACT_APP_BACKEND_URL}/file/generateToken`, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: token
    }
  })
    .then(response => response.json())
    .then(response => {
      let a = document.createElement('a');
      a.href = `${process.env.REACT_APP_BACKEND_URL}/file/download/${file.id}?token=${response.token}`;
      a.download = file.name;
      a.click();
    });
};

export default {
  download
};
