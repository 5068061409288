import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { change } from 'redux-form';
// eslint-disable-next-line import/no-extraneous-dependencies
import { connect } from 'react-redux';
import { FileField, REDUX_FORM_NAME, LinearProgress } from 'react-admin';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import request from '../../services/request';
import api from '../../services/api';

const FILE_STATES = ['none', 'awiting', 'uploading', 'done', 'failed'];

const MyFileField = ({ source, title, record = {} }) => {
  const url = get(record, source);
  const tit = get(record, title);
  return (
    <FileField
      record={{ url: `${process.env.REACT_APP_BACKEND_URL}${url}`, title: tit }}
      source="url"
      title="title"
      style={{
        display: 'inline',
        verticalAlign: 'middle'
      }}
    />
  );
};

const UploadField = ({ record, form, dispatch }) => {
  const { rawFile } = record;
  const [completed, setCompleted] = React.useState(0);
  const [currentFile, setCurrentFile] = React.useState();
  const [fileState, setFileState] = React.useState(FILE_STATES[0]);
  React.useEffect(() => {
    const upload = file => {
      const config = {
        onUploadProgress(progressEvent) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setCompleted(percentCompleted);
        }
      };

      const data = new FormData();
      data.append('files', file);

      request
        .post(api.upload, data, config)
        .then(res => {
          dispatch(change(REDUX_FORM_NAME, form, res[0]));
          setFileState(FILE_STATES[3]);
        })
        .catch(error => {
          setFileState(FILE_STATES[4]);
        });
    };
    // start to upload new file
    if (record !== currentFile) {
      setCurrentFile(record);
      if (rawFile) {
        setFileState(FILE_STATES[1]);
      }
    }

    if (fileState === FILE_STATES[1]) {
      upload(rawFile);
      setFileState(FILE_STATES[2]);
    }

    return () => {};
  }, [record, currentFile, fileState, dispatch, form, rawFile]);

  return (
    <div style={{ position: 'relative' }}>
      <div
        style={{
          float: 'right',
          top: '-33px',
          left: '45px',
          position: 'absolute'
        }}
      >
        <MyFileField
          record={rawFile || currentFile}
          source={rawFile ? 'preview' : 'url'}
          title="name"
        />
        {fileState === FILE_STATES[3] ? (
          <DoneIcon
            color="primary"
            style={{
              marginLeft: '5px',
              display: 'inline',
              verticalAlign: 'middle'
            }}
          />
        ) : null}
        {fileState === FILE_STATES[4] ? (
          <ErrorIcon
            color="error"
            style={{
              marginLeft: '5px',
              display: 'inline',
              verticalAlign: 'middle'
            }}
          />
        ) : null}
      </div>
      {fileState === FILE_STATES[2] ? (
        <LinearProgress
          variant="determinate"
          value={completed}
          style={{ width: '100%', margin: 'auto' }}
        />
      ) : null}
    </div>
  );
};

UploadField.propTypes = {
  record: PropTypes.object
};
UploadField.defaultProps = {
  record: {}
};
export default connect()(UploadField);
