import React from 'react';
import { ContentCopy } from '@material-ui/icons';
import { CREATE, withDataProvider, Button } from 'react-admin';

function DuplicateButton(props) {
  const { id, dataProvider } = props;

  function handleConfirm() {
    const resource = 'newsletters/' + id + '/duplicate';
    dataProvider(
      CREATE,
      resource,
      { data: {} },
      {
        onSuccess: {
          notification: { body: 'Duplicate newsletter succeed', level: 'info' },
          redirectTo: '/newsletters'
        },
        onError: {
          notification: {
            body: 'Error: Duplicate newsletter failed',
            level: 'warning'
          }
        }
      }
    );
  }

  return (
    <span>
      <Button label="Duplicate" color="primary" onClick={handleConfirm}>
        <ContentCopy style={{ marginRight: 5 }} />
      </Button>
    </span>
  );
}

export default withDataProvider(DuplicateButton);
