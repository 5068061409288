import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import documentPreview from '../assets/document_preview.png';
import pptPreview from '../assets/ppt_preview.png';
import imagePreview from '../assets/image_preview.jpg';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FilePreviewField = ({ source, src, record = {}, resource }) => {
  return (
    <div
      style={{
        textAlign: 'center'
      }}
    >
      {record.file && record.file.ext === '.pdf' ? (
        <Fragment>
          <Document
            style={{ width: '100%' }}
            file={`${process.env.REACT_APP_BACKEND_URL}${record[source][src]}`}
          >
            <Page pageNumber={1} />
          </Document>
        </Fragment>
      ) : (
          <img
            style={{ height: '30em', maxWidth: '90%', objectFit: 'contain' }}
            alt=""
            src={
              resource !== 'documents' && resource !== 'templates'
                ? record.file && record.file.ext === '.pdf'
                  ? documentPreview
                  : record.file &&
                    (record.file.ext === '.eps' ||
                      record.file.ext === '.tif' ||
                      record.file.ext === '.ai')
                    ? imagePreview
                    : `${process.env.REACT_APP_BACKEND_URL}${record[source][src]}`
                : record.file && record.file.ext === '.pptx'
                  ? pptPreview
                  : documentPreview
            }
          />
        )}
    </div>
  );
};

FilePreviewField.propTypes = {
  record: PropTypes.object,
  source: PropTypes.string.isRequired
};

export default FilePreviewField;
