import React from 'react';
import { linkToRecord } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { getDateTimeOf } from '../utils/common';
import CardActions from '@material-ui/core/CardActions';
import IconButton from '@material-ui/core/IconButton';
import DownloadIcon from '@material-ui/icons/FileDownload';
import services from '../services/services';
import documentPreview from '../assets/document_preview.png';
import pptPreview from '../assets/ppt_preview.png';
import imagePreview from '../assets/image_preview.jpg';
import videoPreview from '../assets/video_preview.png';

const fontStyle = {
  wordBreak: 'break-word',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '14px',
  lineHeight: '1.29'
};

function fetchPreviewImg(basePath, file = null, previewimg = null) {
  if (basePath === '/videos') {
    return previewimg
      ? `${process.env.REACT_APP_BACKEND_URL}/file/download/${
          previewimg.id
        }?token=${localStorage.getItem('jwt')}`
      : videoPreview;
  } else if (basePath === '/documents' || basePath === '/templates') {
    return file && file.ext === '.pptx' ? pptPreview : documentPreview;
  } else {
    return file && file.ext === '.pdf'
      ? documentPreview
      : file &&
        (file.ext === '.eps' || file.ext === '.tif' || file.ext === '.ai')
      ? imagePreview
      : `${process.env.REACT_APP_BACKEND_URL}\\${file && file.url}`;
  }
}

function MediaItem(props) {
  const {
    item: { title, file, previewimg, createdAt: time },
    basePath
  } = props;

  function handleDownload(event) {
    // prevent default event
    event.preventDefault();
    // download file
    services.download(basePath === '/videos' ? props.item.pcvideo : file);
  }

  return (
    <div style={{ margin: '1em' }}>
      <Card
        style={{
          width: '100%',
          minHeight: '100%',
          margin: '0.5em',
          display: 'inline-block',
          verticalAlign: 'top'
        }}
      >
        <div
          style={{
            backgroundColor: '#f4f4f4',
            textAlign: 'center'
          }}
        >
          <CardMedia
            style={{
              display: 'inline-block',
              height: '15em',
              width: '100%',
              objectFit: 'contain'
            }}
            component="img"
            alt=""
            image={fetchPreviewImg(basePath, file && file, previewimg)}
          />
        </div>
        <CardContent style={{ paddingBottom: '0px' }}>
          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            style={{
              ...fontStyle,
              color: '#404040',
              WebkitLineClamp: 2,
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              paddingBottom: 20,
              height: 34
            }}
          >
            {title}
          </Typography>
          <Typography
            gutterBottom
            component="h2"
            style={{
              ...fontStyle,
              fontSize: '12px',
              color: '#767676'
            }}
          >
            {`Added: ${getDateTimeOf(time)}`}
          </Typography>
        </CardContent>
        <CardActions style={{ padding: '0px 4px' }}>
          <IconButton
            style={{ marginLeft: 'auto' }}
            onClick={handleDownload}
            aria-label="show more"
          >
            <DownloadIcon />
          </IconButton>
        </CardActions>
      </Card>
    </div>
  );
}

const MediaGrid = ({ ids, data, basePath }) => {
  return (
    <Grid container spacing={0}>
      {ids.map(id => {
        return (
          <Grid item xs={12} sm={6} md={3} lg={3} key={id}>
            <Link
              key={id}
              style={{ display: 'block', textDecoration: 'none' }}
              to={linkToRecord(basePath, id, 'show')}
            >
              <MediaItem basePath={basePath} item={data[id]} key={id} />
            </Link>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default MediaGrid;
