import React, { useRef } from 'react';
import MuiButton from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import CopyIcon from '@material-ui/icons/ContentCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function CopyLinkButton(props) {
  const [open, setOpen] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const textAreaRef = useRef(null);
  const { record, label } = props;

  function handleClickOpen() {
    setIsCopied(false);
    setOpen(true);
  }

  function handleClose() {
    setIsCopied(false);
    setOpen(false);
  }

  return (
    <span>
      <div>
        <MuiButton
          style={{
            marginBottom: '20px',
            paddingLeft: '0px',
            color: '#3f51b5'
          }}
          onClick={handleClickOpen}
        >
          <CopyIcon style={{ paddingRight: '0.5em' }} />
          {label}
        </MuiButton>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Link</DialogTitle>
        <DialogContent>
          <DialogContentText ref={textAreaRef}>
            {`${window.location.origin}/#/share?id=${record.id}`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {isCopied && (
            <DialogContentText color="primary">
              link successfully copied
            </DialogContentText>
          )}
          <CopyToClipboard
            text={`${window.location.origin}/#/share?id=${record.id}`}
            onCopy={() => setIsCopied(true)}
          >
            <MuiButton color="primary">Copy link</MuiButton>
          </CopyToClipboard>
        </DialogActions>
      </Dialog>
    </span>
  );
}

export default CopyLinkButton;
