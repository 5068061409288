import newsTemp from './newsTemp.json';
import mjmlTemp from './mjmlTemp.json';
import MJMLParser from 'mjml-parser-xml';

const copy = obj => {
  var newobj = obj.constructor === Array ? [] : {};
  if (typeof obj !== 'object') {
    return;
  }
  for (var i in obj) {
    newobj[i] = typeof obj[i] === 'object' ? copy(obj[i]) : obj[i];
  }
  return newobj;
};

const uploadImgFile = file =>
  new Promise((resolve, reject) => {
    if (typeof file === 'string') {
      resolve({ url: file });
      return;
    }

    const data = new FormData();
    data.append('files', file);
    data.append('provider', 'local');
    fetch(process.env.REACT_APP_BACKEND_URL + '/upload', {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('jwt')
      },
      body: data
    })
      .then(res => res.json())
      .then(response => resolve(response[0]))
      .catch(error => reject(error));
  });

const convertNewsToMjmlSection = (src, media, title, subtitle, url) => {
  let newsJson = copy(newsTemp);
  // img
  newsJson[0].children[0].children[0].children[0].attributes.src = src;
  newsJson[0].children[0].children[0].children[0].attributes.href = url;
  newsJson[0].children[0].children[0].children[0].attributes.title = title;
  // media
  newsJson[0].children[0].children[1].children[0].content = `<b>${media}</b>`;
  // switch font family English with Arial and Chinese with 微软雅黑
  newsJson[0].children[0].children[1].children[0].attributes[
    'font-family'
  ] = media.match(
    /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/
  )
    ? '微软雅黑, sans-serif, serif'
    : 'Arial, sans-serif, serif';
  // title
  const titleFont = title.match(
    /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/
  )
    ? '微软雅黑, sans-serif, serif'
    : 'Arial, sans-serif, serif';
  newsJson[0].children[0].children[1].children[1].attributes.title = title;
  newsJson[0].children[0].children[1].children[1].content = `<a href="${url}" style="text-decoration: none" target=_blank><b><span style="color: black; font-family: ${titleFont};">${title}</span></b></a>`;
  // subtitle
  const subTitleFont = subtitle.match(
    /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/
  )
    ? '微软雅黑, sans-serif, serif'
    : 'Arial, sans-serif, serif';
  newsJson[0].children[0].children[1].children[2].attributes.title = title;
  newsJson[0].children[0].children[1].children[2].content = `<a href="${url}" style="text-decoration: none" target=_blank><span style="color:#595959; font-family: ${subTitleFont};">${subtitle}</span></a>`;

  return newsJson;
};

const wrapTagToContent = (childrenTag, startTag = '', endTag = '') => {
  let style = '';
  Object.keys(childrenTag.attributes).forEach(att => {
    style += att + ': ' + childrenTag.attributes[att] + ';';
  });

  startTag += '<' + childrenTag.tagName + ' style="' + style + '" >';
  endTag += '</' + childrenTag.tagName + '>';

  return childrenTag.children
    ? wrapTagToContent(
        childrenTag.children[0],
        startTag + (childrenTag.content ? childrenTag.content : ''),
        endTag
      )
    : startTag + childrenTag.content + endTag;
};

const addATagForHrefText = mjml => {
  // if mj-text has children, wrap children tag to content. cause MJML Json cannot parse tag for mj-text.
  if (mjml.tagName === 'mj-text' && mjml.children) {
    mjml.content = mjml.content
      ? mjml.content + wrapTagToContent(mjml.children[0])
      : wrapTagToContent(mjml.children[0]);
  }

  if (mjml.tagName === 'mj-text' && mjml.attributes.href) {
    // wrap attributes to <span style=""></span> tag.
    let style = '';
    Object.keys(mjml.attributes).forEach(att => {
      style += att + ': ' + mjml.attributes[att] + ';';
    });

    const span = '<span style="' + style + '" >' + mjml.content + '</span>';
    // wrap 'content' with <a> tag include href.
    const content =
      '<a href="' +
      mjml.attributes.href +
      '" style="text-decoration: none" target=_blank>' +
      span +
      '</a>';
    mjml.content = content;
  }

  if (mjml.children) {
    mjml.children.forEach(subMjml => addATagForHrefText(subMjml));
  }
};

const addGenerateMjmlFeature = requestHandler => (type, resource, params) => {
  // media resource.
  if (
    (type === 'CREATE' || type === 'UPDATE') &&
    (resource === 'icons' ||
      resource === 'logos' ||
      resource === 'photographies' ||
      resource === 'documents' ||
      resource === 'templates')
  ) {
    const file = params.data.file.rawFile || params.data.file.url;
    return uploadImgFile(file)
      .then(file => file.id)
      .then(id => {
        return requestHandler(type, resource, {
          ...params,
          data: {
            ...params.data,
            file: id
          }
        });
      });
  }

  if ((type === 'CREATE' || type === 'UPDATE') && resource === 'newsletters') {
    // used for newsletter email send.
    if (params.type === 'send') {
      return requestHandler(type, resource, params);
    }

    if (params.data.extra.templateType === 'free') {
      const mjmlStr = localStorage.getItem('gjs-html');
      const mjml = MJMLParser(mjmlStr);
      addATagForHrefText(mjml);
      return requestHandler(type, resource, {
        ...params,
        data: {
          ...params.data,
          mjml: mjml,
          extra: {
            ...params.data.extra,
            html: mjmlStr
          }
        }
      });
    }

    if (params.data.extra.newsData) {
      // fetch all pictures.
      const imgFiles = params.data.extra.newsData.map(
        value => value.picture.rawFile || value.picture.url
      );

      return Promise.all(imgFiles.map(uploadImgFile))
        .then(imgs =>
          imgs.map(img => ({
            url: img.url
          }))
        )
        .then(transformedImgs => {
          // convet news to mjml format.
          const newsMjmlJson = params.data.extra.newsData.map((news, index) =>
            convertNewsToMjmlSection(
              transformedImgs[index].url,
              news.media,
              news.title,
              news.subtitle,
              news.url
            )
          );
          params.data.extra.newsData.map(
            (news, index) =>
              (params.data.extra.newsData[index].picture =
                transformedImgs[index])
          );
          let tempMjmlJson = copy(mjmlTemp);
          // append header and footer.
          let spreadNewsMjmlJson = [];
          newsMjmlJson.forEach(element => {
            spreadNewsMjmlJson.push(element[0]);
            spreadNewsMjmlJson.push(element[1]);
          });
          spreadNewsMjmlJson.splice(0, 0, tempMjmlJson.children[0].children[0]);
          spreadNewsMjmlJson.push(tempMjmlJson.children[0].children[1]);

          // append news to template.
          tempMjmlJson.children[0].children = spreadNewsMjmlJson;
          return requestHandler(type, resource, {
            ...params,
            data: {
              ...params.data,
              mjml: tempMjmlJson
            }
          });
        });
    }
  }

  // for other request types and resources, fall back to the default request handler
  return requestHandler(type, resource, params);
};

export default addGenerateMjmlFeature;
