import React from 'react';
import { AppBar } from 'react-admin';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

const styles = {
  title: {
    flex: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  spacer: {
    flex: 1
  }
};

const CustomAppBar = ({ classes, ...props }) => (
  <AppBar {...props}>
    <Typography
      variant="title"
      color="inherit"
      className={classes.title}
      id="react-admin-title"
    />
    <Hidden xsDown>
      <Typography variant="title" color="inherit">
        J&J China Comms Central
      </Typography>
    </Hidden>
    <span className={classes.spacer} />
  </AppBar>
);

export default withStyles(styles)(CustomAppBar);
