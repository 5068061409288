import React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  CardActions,
  Mutation,
  ChipField,
  EditButton,
  Button,
  ReferenceManyField,
  Datagrid,
  FunctionField
} from 'react-admin';
import { Send } from '@material-ui/icons';
import MjmlField from './MjmlField';
import SendTestButton from './SendTestButton';
import DuplicateButton from './DuplicateButton';
import DeleteButtonWithConfirmation from '../../components/DeleteButtonWithConfirmation';

const urlLimitedLenght = 70;

const sendOptions = {
  onSuccess: {
    notification: { body: 'Email send succeed', level: 'info' }
  },
  onError: {
    notification: { body: 'Error: email send failed', level: 'warning' }
  }
};

const ShowActions = ({ basePath, data, resource }) => (
  <CardActions>
    {data &&
      (data.status === 'Sent' ? (
        <div>
          <DuplicateButton id={data.id} />
          <DeleteButtonWithConfirmation
            basePath={basePath}
            record={data}
            resource={resource}
          />
        </div>
      ) : (
        <div>
          <Mutation
            type="UPDATE"
            resource="newsletters"
            payload={{ id: data.id, data: { status: 'Sent' }, type: 'send' }}
            options={sendOptions}
          >
            {send => (
              <Button label="Send" color="primary" onClick={send}>
                <Send style={{ marginRight: 5 }} />
              </Button>
            )}
          </Mutation>
          <SendTestButton id={data.id} />
          <EditButton basePath={basePath} record={data} />
          <DuplicateButton id={data.id} />
          <DeleteButtonWithConfirmation
            basePath={basePath}
            record={data}
            resource={resource}
          />
        </div>
      ))}
  </CardActions>
);

const ShowTitle = ({ record }) => {
  return <span>NewsLetter {record ? `${record.subject}` : ''}</span>;
};

const NewsLetterShow = props => {
  return (
    <Show {...props} title={<ShowTitle />} actions={<ShowActions />}>
      <SimpleShowLayout>
        <TextField source="subject" />
        <FunctionField source="to" render={record => record.to.join(' / ')} />
        <ChipField style={{ display: '-webkit-inline-box' }} source="status" />
        <MjmlField />
        <ReferenceManyField
          label="Analysis"
          reference="links/aggregate"
          target="newsletter"
          perPage={100}
        >
          <Datagrid>
            <TextField source="title" />
            <FunctionField
              label="URL"
              render={record =>
                `${
                  record.url.length > urlLimitedLenght
                    ? `${record.url.substr(0, urlLimitedLenght)}...`
                    : record.url
                }`
              }
            />
            {/* <TextField source="url" /> */}
            <TextField source="clicksCount" />
          </Datagrid>
        </ReferenceManyField>
      </SimpleShowLayout>
    </Show>
  );
};

export default NewsLetterShow;
