/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import videojs from 'video.js';
import './index.css';

export default class VideoPlayer extends React.Component {
  componentDidMount() {
    // fetch video
    this.props.sources[0].src = this.props.isShare
      ? `${this.props.sources[0].src}`
      : `${this.props.sources[0].src}?token=${localStorage.getItem('jwt')}`;

    this.player = videojs(
      this.videoNode,
      this.props,
      function onPlayerReady() {}
    );
  }

  // destroy player on unmount
  componentWillUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  }

  handleClick = () => {
    const { autoFullScreen } = this.props;
    if (autoFullScreen && !this.player.paused()) {
      this.player.requestFullscreen();
    }
  };

  // wrap the player in a div with a `data-vjs-player` attribute
  // so videojs won't create additional wrapper in the DOM
  // see https://github.com/videojs/video.js/pull/3856
  render() {
    return (
      <div onClick={this.handleClick}>
        <div
          data-vjs-player
          ref={ref => {
            this.divRef = ref;
            return ref;
          }}
        >
          <video
            ref={node => {
              this.videoNode = node;
              return node;
            }}
            className="video-js vjs-default-skin vjs-16-9 vjs-big-play-centered"
          />
        </div>
      </div>
    );
  }
}
